<template>
  <div>
    <h1 class="text-black font-bold text-lg">Add Track</h1>

    <form @submit.prevent="submitForm" class="mt-4">
      <div class="pb-16">
        <div class="mb-4">
          <label :class="{
            'text-sm block text-gray-700 font-medium mb-1': true,
            'text-red-500': errors.title
          }">Track Title</label>

          <input type="text" v-model="trackPayload.title" :class="{
            'py-2 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2': true,
            'border-red-500': errors.title
          }" />
          <span class="text-xs text-gray-500">If it is a single, use the track title.</span>
        </div>

        <div class="mb-4">
          <label :class="{
            'text-sm block text-gray-700 font-medium mb-1': true,
            'text-red-500': errors.producer
          }">Producer</label>

          <input type="text" :class="{
            'py-2 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2': true,
            'border-red-500': errors.producer
          }" v-model="trackPayload.producer" />
        </div>

        <div class="mb-4">
          <label :class="{
            'text-sm block text-gray-700 font-medium mb-1': true,
            'text-red-500': errors.version
          }">Track Version</label>

          <input type="text" :class="{
            'py-2 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2': true,
            'border-red-500': errors.version
          }" v-model="trackPayload.version" />
        </div>

        <div class="mb-4">
          <label :class="{
            'text-sm block text-gray-700 font-medium mb-1': true,
            'text-red-500': errors.year
          }">Recording Year</label>

          <input type="text" :class="{
            'py-2 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2': true,
            'border-red-500': errors.year
          }" v-model="trackPayload.year" />
        </div>

        <div class="mb-4">
          <label :class="{
            'text-sm block text-gray-700 font-medium mb-1': true,
          }">ISRC (Optional)</label>

          <input type="text" :class="{
            'py-2 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2': true,
          }" v-model="trackPayload.isrc" />
        </div>

        <div class="mb-4">
          <label :class="{
            'text-sm block text-gray-700 font-medium mb-1': true,
            'text-red-500': errors.explicit_content
          }">Explicit Content</label>

          <vs-select class="s-select mb-2" v-model="trackPayload.explicit_content" autocomplete>
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in explicitOption" />
          </vs-select>
          <span class="text-xs text-gray-500">
            Does this track contain offensive language or content?
          </span>
        </div>

        <div class="mb-4">
          <label :class="{
            'text-sm block text-gray-700 font-medium mb-1': true,
            'text-red-500': errors.lyrics
          }">Lyrics</label>

          <textarea rows="3" :class="{
            'py-2 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2': true,
            'border-red-500': errors.lyrics
          }" v-model="trackPayload.lyrics" />
        </div>

        <div class="mb-4">
          <label :class="{
            'text-sm block text-gray-700 font-medium mb-1': true,
          }">Extra Notes (Optional)</label>

          <textarea rows="2" :class="{
            'py-2 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2': true,
          }" v-model="trackPayload.notes" />
        </div>

        <!-- <div>
          <div class="flex justify-between">
            <p class="uppercase text-sm tracking-wider mt-2 mb-3">Artists</p>

            <button
              class="text-primary flex items-center space-x-1 h-8 px-2 hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            >
              <PlusCircleIcon class="w-4 h-4" />
              <span class="text-sm">Add Artist</span>
            </button>
          </div>

          <p class="text-xs text-gray-500">
            Add the main artist and any featured artists.
          </p>

          <div
            class="mb-4 mt-2 border-b border-gray-100/30 pb-4 space-y-2 flex flex-col"
          >
            <div class="space-y-2 bg-blue-50 py-2 px-3 rounded-md">
              <div class="flex items-center justify-between">
                <div class="flex items-center space-x-3">
                  <div>
                    <p class="text-sm mb-1">Horpey</p>
                    <p class="text-xs mb-0 text-gray-500">Primary Artist</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="space-y-2 bg-blue-50 py-2 px-3 rounded-md">
              <div class="flex items-center justify-between">
                <div class="flex items-center space-x-3">
                  <div>
                    <p class="text-sm mb-1">Eureka</p>
                    <p class="text-xs mb-0 text-gray-500">Featured Artist</p>
                  </div>
                </div>
                <button
                  type="button"
                  class="outline-none hover:text-primary focus:text-primary"
                >
                  <XCircleIcon class="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        </div> -->

        <div>
          <div class="flex justify-between">
            <p class="uppercase text-sm tracking-wider mt-2 mb-3">
              Contributors
            </p>

            <button @click="contributorModal = true" type="button"
              class="text-primary flex items-center space-x-1 h-8 px-2 hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
              <PlusCircleIcon class="w-4 h-4" />
              <span class="text-sm">Add Contributor</span>
            </button>
          </div>

          <div class="mb-4 border-b border-gray-100/30 pb-4 space-y-2 flex flex-col">
            <div v-for="(contributor, index) in trackPayload.artists" :key="index"
              class="space-y-2 bg-blue-50 py-2 px-3 rounded-md">
              <div class="flex items-center justify-between">
                <div class="flex items-center space-x-3">
                  <div>
                    <p class="text-sm mb-1">{{ contributor.name }}</p>
                    <p class="text-xs mb-0 text-gray-500">
                      {{ contributor.role }}
                    </p>
                  </div>
                </div>
                <button @click="removeArtist(index)" type="button"
                  class="outline-none hover:text-primary focus:text-primary">
                  <XCircleIcon class="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="flex justify-between">
            <p class="uppercase text-sm tracking-wider mt-2 mb-3">Writers</p>

            <button type="button" @click="writerModal = true"
              class="text-primary flex items-center space-x-1 h-8 px-2 hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
              <PlusCircleIcon class="w-4 h-4" />
              <span class="text-sm">Add Writer</span>
            </button>
          </div>

          <div class="mb-4 border-b border-gray-100/30 pb-4 space-y-2 flex flex-col">
            <div class="flex flex-col space-y-2">
              <div v-for="(writer, index) in trackPayload.writers"
                class="flex items-center justify-between space-y-2 bg-blue-50 py-2 px-3 rounded-md">
                <p class="text-sm mb-0">{{ writer }}</p>

                <button @click="removeWriter(index)" type="button"
                  class="outline-none hover:text-primary focus:text-primary m-0">
                  <XCircleIcon class="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="flex justify-between">
            <p class="uppercase text-sm tracking-wider mt-2 mb-3">Splits</p>

            <button v-if="maxSplitPercentage !== 0" type="button" @click="splitModal = true"
              class="text-primary flex items-center space-x-1 h-8 px-2 hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
              <PlusCircleIcon class="w-4 h-4" />
              <span class="text-sm">Add Split</span>
            </button>
          </div>

          <div class="mb-4 border-b border-gray-100/30 space-y-2 flex flex-col">
            <p class="text-xs text-gray-500">
              Do you want to share the royalties with other artists?
            </p>

            <div class="mb-4 mt-2 space-y-2 flex flex-col">
              <div v-if="split.split_percentage" v-for="(split, index) in trackPayload.artists"
                class="space-y-2 bg-blue-50 py-2 px-3 rounded-md">
                <div class="flex items-center justify-between">
                  <div class="flex items-center space-x-3">
                    <div>
                      <p class="text-sm mb-1">{{ split.name }}</p>
                      <p class="text-xs mb-0 text-gray-500">{{ split.role }}</p>
                    </div>
                  </div>
                  <span class="flex space-x-3">
                    <span class="text-sm"> {{ split.split_percentage }}% </span>

                    <button @click="removeSplit(index)" type="button"
                      class="outline-none hover:text-primary focus:text-primary">
                      <XCircleIcon class="w-4 h-4" />
                    </button></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <p class="uppercase text-sm tracking-wider mt-2 mb-3">Audio</p>
          <p class="text-xs text-gray-500 mb-2">
            Audio file must be in wav-file, sample rate at 44.1 kHz, 16 bit and
            the file must end with .wav
          </p>

          <!-- Tracks -->
          <div class="mb-4">
            <MUpload :maxSize="200914560" v-model="trackPayload.audio_path" path="release_track" accept="audio/mp3, audio/wav"
              format="wav" id="tracks" ref="tracks"
              info="Please note that this may take a while to upload. Please do not close this window until the upload is complete." />
          </div>
        </div>
      </div>

      <div class="fixed bottom-0 pb-4 bg-white w-full">
        <button type="submit"
          class="bg-primary mt-4 text-white py-3 px-4 hover:bg-primary rounded hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
          Add Track
        </button>
      </div>
    </form>

    <!-- Add Writer -->
    <vs-popup title="Add Writer" :active.sync="writerModal">
      <form @submit.prevent="addWriter()">
        <div class="mb-4">
          <label class="text-sm block text-gray-700 font-medium mb-1">Writer name</label>

          <input type="text" required v-model="writerPayload.name"
            class="py-2 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2" />
        </div>

        <button type="submit"
          class="bg-primary w-full text-white py-3 px-4 hover:bg-primary rounded hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
          Add Writer
        </button>
      </form>
    </vs-popup>

    <!-- Add Contributor -->
    <vs-popup title="Add Contributor" :active.sync="contributorModal">
      <form @submit.prevent="addContributor()">
        <div class="mb-4">
          <label class="text-sm block text-gray-700 font-medium mb-1">Name</label>

          <input type="text" required v-model="contributorPayload.name"
            class="py-2 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2" />
          <span class="text-xs text-gray-500">
            eg. Artist name, Producer name, etc
          </span>
        </div>

        <div class="mb-4">
          <label class="text-sm block text-gray-700 font-medium mb-1">Email address</label>

          <input type="email" v-model="contributorPayload.email"
            class="py-2 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2" />
        </div>

        <div class="mb-4">
          <label class="text-sm block text-gray-700 font-medium mb-1">Select Role</label>

          <vs-select required class="s-select mb-2" v-model="contributorPayload.role" autocomplete>
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in allRoles" />
          </vs-select>
        </div>

        <div class="mb-4">
          <label class="text-sm block text-gray-700 font-medium mb-1">
            Is this contributor a Spotify artist?
          </label>

          <vs-select class="s-select mb-2" v-model="contributorPayload.spotify" autocomplete>
            <vs-select-item disabled value="" text="Select option" />
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in spotifyOption" />
          </vs-select>
        </div>

        <button type="submit"
          class="bg-primary mt-4 w-full text-white py-3 px-4 hover:bg-primary rounded hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
          Add Contributor
        </button>
      </form>
    </vs-popup>

    <!-- Add Split -->
    <vs-popup title="Add Split" :active.sync="splitModal">
      <form @submit.prevent="addSplit()">
        <div class="mb-4">
          <label class="text-sm block text-gray-700 font-medium mb-1">Select Contributor</label>
          <vs-select required class="s-select mb-2" v-model="splitPayload.user" autocomplete>
            <vs-select-item disabled :value="null" text="Select a contributor" />
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in contributorList" />
          </vs-select>
        </div>

        <div class="mb-4">
          <label class="text-sm block text-gray-700 font-medium mb-1">Select Split Percentage (%)
            <span class="text-sm text-gray-300 pl-2">{{ maxSplitPercentage }} % left
            </span>
          </label>

          <div class="px-2 mt-2">
            <vs-slider :max="maxSplitPercentage" v-model="splitPayload.split_percentage" />
          </div>
        </div>

        <button type="submit"
          class="bg-primary mt-4 w-full text-white py-3 px-4 hover:bg-primary rounded hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
          Add Split
        </button>
      </form>
    </vs-popup>
  </div>
</template>

<script>
import { PlusCircleIcon, XCircleIcon } from "vue-feather-icons";
import MUpload from "@/components/MUpload.vue";

export default {
  components: { MUpload, PlusCircleIcon, XCircleIcon },
  data() {
    return {
      trackPayload: {
        title: "",
        explicit_content: "",
        year: "",
        version: "",
        producer: "",
        audio_path: "",
        notes: "",
        isrc: "",
        lyrics: "",
        writers: [],
        artists: [],
      },
      errors: {
        title: false,
      },
      explicitOption: [
        { value: "yes", text: "Yes" },
        { value: "no", text: "No" },
      ],
      spotifyOption: [
        { value: "yes", text: "Yes" },
        { value: "no", text: "No" },
      ],
      allRoles: [
        { value: "arranger", text: "Arranger" },
        { value: "bassist", text: "Bassist" },
        { value: "composer", text: "Composer" },
        { value: "cellist", text: "Cellist" },
        { value: "choir", text: "Choir" },
        { value: "drummer", text: "Drummer" },
        { value: "featured-artist", text: "Featured Artist" },
        { value: "guitarist", text: "Guitarist" },
        { value: "keyboardist", text: "Keyboardist" },
        { value: "mastering-engineer", text: "Mastering Engineer" },
        { value: "mixing-engineer", text: "Mixing Engineer" },
        { value: "orchestra", text: "Orchestra" },
        { value: "percussionist", text: "Percussionist" },
        { value: "primary-artist", text: "Primary Artist" },
        { value: "producer", text: "Producer" },
        { value: "remixer", text: "Remixer" },
        { value: "saxophonist", text: "Saxophonist" },
        { value: "songwriter", text: "Songwriter" },
        { value: "vocalist", text: "Vocalist" },
        { value: "violinist", text: "Violinist" },
      ],
      splitModal: false,
      splitPayload: {
        user: null,
        split_percentage: "",
      },
      writerModal: false,
      writerPayload: {
        name: "",
      },
      contributorModal: false,
      contributorPayload: {
        name: "",
        role: "",
        email: "",
        split_percentage: "",
      },
      maxSplitPercentage: 100,
    };
  },
  mounted() {
    this.updateMaxSplitPercentage();
  },
  computed: {
    fieldsValidated() {
      this.errors.title = !this.trackPayload.title;
      this.errors.producer = !this.trackPayload.producer;
      this.errors.version = !this.trackPayload.version;
      this.errors.year = !this.trackPayload.year;
      this.errors.explicit_content = !this.trackPayload.explicit_content;
      this.errors.lyrics = !this.trackPayload.lyrics;

      return (
        this.trackPayload.title &&
        this.trackPayload.year &&
        this.trackPayload.version &&
        this.trackPayload.producer &&
        this.trackPayload.audio_path &&
        this.trackPayload.artists.length > 0 &&
        this.trackPayload.writers.length > 0
      );
    },
    contributorList() {
      return this.trackPayload.artists.map((item, index) => {
        return {
          value: index,
          text: item.name,
        };
      });
    },
    user() {
      return this.$store.getters.user || {};
    },
  },
  methods: {
    getMainArtistSplitPercentage() {
      const total = this.trackPayload?.artists.reduce((acc, item) => {
        return acc + item.split_percentage;
      }, 0)
      return 100 - total;
    },
    submitForm() {
      if (this.fieldsValidated) {
        const mainArtist = {
          email: this.user?.email,
          name: this.user?.artist_name,
          role: "primary-artist",
          split_percentage: this.getMainArtistSplitPercentage(),
          spotify: this.user?.spotify_id ? 'yes' : 'no',
        }

        this.trackPayload.artists.push(mainArtist)

        this.$emit("submit");
        this.$store.commit("ADD_TRACK", this.trackPayload);
        this.trackPayload = {
          title: "",
          explicit_content: "",
          year: "",
          version: "",
          producer: "",
          audio_path: "",
          lyrics: "",
          isrc: "",
          notes: "",
          writers: [],
          artists: [],
        };
        this.$refs.tracks.resetFiles()
      } else {
        this.$toast.error("Please fill all required fields");
      }
    },
    addWriter() {
      this.trackPayload.writers.push(this.writerPayload.name);
      this.writerPayload.name = "";
      this.writerModal = false;
    },
    removeWriter(index) {
      this.trackPayload.writers.splice(index, 1);
    },
    removeSplit(index) {
      const copyData = this.trackPayload.artists[index];
      copyData.split_percentage = "";

      this.trackPayload.artists.splice(index, 1);
      this.trackPayload.artists.push(copyData);
      this.updateMaxSplitPercentage();
    },
    addContributor() {
      this.trackPayload.artists.push({
        ...this.contributorPayload,
        split_percentage: "",
      });

      this.contributorPayload = {
        name: "",
        role: "",
        email: "",
        split_percentage: "",
        spotify: "",
      };
      this.contributorModal = false;
    },
    removeArtist(index) {
      this.trackPayload.artists.splice(index, 1);
    },
    updateMaxSplitPercentage() {
      let totalSplitPercentage = 0;
      this.trackPayload.artists.forEach((contributor) => {
        if (contributor.split_percentage) {
          totalSplitPercentage += contributor.split_percentage;
        }
      });

      this.maxSplitPercentage = 100 - totalSplitPercentage;
    },
    addSplit() {
      if (
        this.splitPayload.split_percentage === 0 ||
        this.splitPayload.split_percentage === "" ||
        this.splitPayload.user === null
      ) {
        this.$toast.error("Please select a contribuor and set a valid split percentage and try again");
      } else {
        const index = this.splitPayload.user;

        this.trackPayload.artists[index].split_percentage =
          this.splitPayload.split_percentage;

        this.splitPayload = {
          user: null,
          split_percentage: "",
        };


        this.updateMaxSplitPercentage();

        this.splitModal = false;
      }
    },
  },
};
</script>
