<template>
  <div class="border-b border-gray-100/30 pb-4">
    <p class="text-sm pb-2">{{ label }}</p>

    <div v-if="checkUpload()" class="flex items-center justify-center w-full mb-1">
      <label :for="id"
        class="border-primary-100 flex flex-col items-center justify-center w-full h-24 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-50">
        <div class="flex flex-col items-center justify-center">
          <UploadIcon size="26" class="w-6 h-6 mb-2 text-gray-400" />

          <p class="text-sm text-gray-500">
            <span class="font-semibold">Click to upload </span>
            <span class="text-xs text-gray-500"> ({{ format }}) </span>
          </p>
        </div>

        <input :id="id" type="file" class="hidden" :accept="accept" :multiple="multiple" @change="onFileChange" />
      </label>
    </div>
    <span v-if="info" class="text-blue-500 bg-blue-50 text-xs p-2 block mb-2">{{ info }}</span>

    <div class="space-y-2">
      <div v-for="(file, index) in files" :key="index"
        class="flex items-center justify-between border border-gray-100/40 p-2 rounded-md">
        <div class="flex items-center space-x-3">
          <FileIcon size="26" class="text-primary" />

          <div>
            <p class="text-sm mb-1">{{ file.name }}</p>
            <p class="text-xs mb-0 text-gray-500">
              {{ computeFileSize(file.size) }}
            </p>
          </div>
        </div>

        <Loading v-if="loading" size="25px" />

        <button v-else type="button" @click="removeFile(index)"
          class="outline-none hover:text-primary focus:text-primary">
          <XCircleIcon size="20" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  FileIcon,
  XCircleIcon,
  UploadIcon,
} from "vue-feather-icons";
import Loading from "./Loading";

export default {
  name: "MUpload",
  components: {
    FileIcon,
    Loading,
    XCircleIcon,
    UploadIcon,
  },
  props: {
    modelValue: {
      type: [Array, String, Object],
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: "",
    },
    format: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    path: {
      type: String,
      default: "",
    },
    info: {
      type: String,
      default: "",
    },
    maxSize: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      files: [],
      loading: false,
    };
  },
  methods: {
    computeFileSize(size) {
      if (size < 1024) {
        return size + " bytes";
      } else if (size >= 1024 && size < 1048576) {
        return (size / 1024).toFixed(2) + " KB";
      } else if (size >= 1048576) {
        return (size / 1048576).toFixed(2) + " MB";
      }
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      if (this.isValidated(files[0])) {
        this.uploadImageService(files);
        this.createFile(files);
      }
    },
    isValidated(file) {
      if (this.maxSize > 0 && file.size > this.maxSize) {
        this.$toast.info(
          "File Upload",
          "File size is too large, please try again with a file of size less than " +
          this.computeFileSize(this.maxSize),
          this.$toastPosition
        );
        return false;
      }
      return true;
    },
    createFile(files) {
      for (const element of files) {
        const file = element;
        this.files.push(file);
      }
    },
    checkUpload() {
      if (this.files.length > 0) {
        return !!this.multiple;
      }
      return true;
    },
    uploadImageService(files) {
      this.loading = true;

      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("path", this.path);

      let fetch = {
        path: `/artist/upload`,
        data: formData,
      };

      this.$store
        .dispatch("postRequest", fetch)
        .then((resp) => {
          const { data } = resp.data;

          this.$emit("input", data?.id);
        })
        .catch((err) => {
          this.files = [];
          if (err.response) {
            this.$toast.info(
              "File Upload",
              err.response.data.message,
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Error",
              "Something went wrong, please try again",
              this.$toastPosition
            );
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetFiles() {
      this.files = [];
    }
  },
};
</script>
